import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';
import { ThemeService } from '../../theme.service';
import { AddAddressComponent } from '../add-address/add-address.component';
import { ModAddressComponent } from '../mod-address/mod-address.component';
import { UpdateAddressComponent } from '../update-address/update-address.component';
@Component({
  selector: 'app-direcciones',
  templateUrl: './direcciones.component.html',
  styleUrls: ['./direcciones.component.scss']
})
export class DireccionesComponent {

  addresses : any = [];

  themeColors: any; 
  isAddCardFormVisible = false; 
  isDialogOpen = false;

  constructor(public themeService: ThemeService,private dialog: MatDialog, private auth : AuthService,private web : WebService, private notification : NotificationService, private loader : LoaderService){
     // LISTAMOS LAS DIRECCIONES DEL USUARIO
     this.loader.open();
     this.web.get(this.web.HOST + "/adress/customer/"+ this.auth.getCustomer()).subscribe(response =>{
       this.loader.close();
       // CAMBIAMOS EL ESTARTUS A UNO MANEJABLE POR EL USUARIO
       if(response.result.length == 0){
         return this.notification.showInfo("No se encontraron direcciones registradas");
       }
 
       for(let item of response.result){
         this.addresses.push({ id : item.id, address :  item.adress ,status :  item.status  , phone :  item.phone, province : item.province.id, corregimiento : item.corregimiento.id })
       }

       //console.log(this.addresses);

       // this.dataSource = new MatTableDataSource<any>(response.result);
       // this.tableService.pager = response.pager;
       // this.tableService.selection.clear();
 
 
       }, err =>{
         this.notification.showError(err);
         this.loader.close();
       });
  }

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
  toggleAddCardForm() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(AddAddressComponent, {
        width: '800px',height:'400px'
        
      });
      dialogRef.afterClosed().subscribe(() => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        window.location.reload();
      });
  }
}
togglemodaddress() {
  if (!this.isDialogOpen) {
    this.isDialogOpen = true; // Marca que el diálogo está abierto
    const dialogRef = this.dialog.open(ModAddressComponent, {
      width: '800px',height:'400px'
      
    });
    dialogRef.afterClosed().subscribe(() => {
      // Restablece el indicador cuando se cierra el diálogo
      this.isDialogOpen = false;
    });
}
}

changeStatus(direction: any){
  this.loader.open();
    this.web.put(direction, this.web.HOST + "/adress/" + direction.id ).subscribe(res =>{
      this.notification.showSuccess("Actualizada correctamente");
      this.loader.close();
      window.location.reload();
    }, err=>{
      this.loader.close();
      this.notification.showError(err);
    });
}


  update(item : any){
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(UpdateAddressComponent, {
        width: '800px',height:'400px',data: { item : item }
        
      });
      dialogRef.afterClosed().subscribe(() => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        window.location.reload();
      });
    }
  }

}
