import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeService } from '../../theme.service';
import {  AddCardFormComponent } from '../add-card-form/add-card-form.component'
import { MatDialog } from '@angular/material/dialog';
import { AddAddressComponent } from '../add-address/add-address.component';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';
import { AuthService } from 'src/app/services/auth/auth.service';




@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})

export class CreditCardComponent {

  tdc: any = [];
  tarjetas : any = [];
  items: Array<any> = [];
  
  themeColors: any; 
  isAddCardFormVisible = false; 
  isDialogOpen = false;

  constructor(public themeService: ThemeService,private dialog: MatDialog,private auth : AuthService,private web : WebService, private notification : NotificationService, private loader : LoaderService ){

    this.loader.open();
    this.items = [];
    this.web.get(this.web.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params =>{
      if(params['result'] != null){
        this.tarjetas = params['result'];
        ///Por cada tarjeta

        for(let i in this.tarjetas){
          let tdc = this.tarjetas[i].creditCard;
          //Se verifica la integridad de la tdc chequeando si posee un token en HecBillingCustomer
          this.web.get(this.web.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params => {
            //Si la tarjeta esta correctamente referenciada
            if(params['result'] != null) {
              switch(tdc.expMonthCreditCard){
                case 1 : tdc.expMonth = "ENERO";
                break;
                case 2 : tdc.expMonth = "FEBRERO";
                break;
                case 3 : tdc.expMonth = "MARZO";
                break;
                case 4 : tdc.expMonth = "ABRIL";
                break;
                case 5 : tdc.expMonth = "MAYO";
                break;
                case 6 : tdc.expMonth = "JUNIO";
                break;
                case 7 : tdc.expMonth = "JULIO";
                break;
                case 8 : tdc.expMonth = "AGOSTO";
                break;
                case 9 : tdc.expMonth = "SEPTIEMBRE";
                break;
                case 10 : tdc.expMonth = "OCTUBRE";
                break;
                case 11: tdc.expMonth = "NOVIEMBRE";
                break;
                case 12 : tdc.expMonth = "DICIEMBRE";
                break;
              }

              //Se carga el nombre del proveedor TDC en la columna indicada
              tdc.expMonthCreditCard = this.tarjetas[i].providerCreditCard.description;
              //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
              tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length-8);
              //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
              tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
              //Se busca el estatus de la TDC de manera de mostrarlo al cliente
              this.web.get(this.web.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + tdc.id).subscribe(parms => {
                this.loader.open();
                //Se guarda la descripcion del estado en la columna correspondiente
                tdc.estatus = parms['result']['statusCreditCard'] ;
                //Se anade la TDC a la tabla correspondiente
                this.tdc.push({ id : tdc.id, type : tdc.expMonthCreditCard, number : tdc.maskCreditCard, status : tdc.estatus});
                this.loader.close();

              }, (err) => {this.notification.showError(err);
                this.loader.close(); });
            }

          }, (err) => {
            this.notification.showError(err);
            this.loader.close();
           } );
        }


      }else{
        //console.log("NO TDC");
        this.loader.close();
      }


    }, err=>{
      this.notification.showError(err);
      this.loader.close();
    });

  }

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
  toggleAddCardForm() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(AddCardFormComponent, {
        width: '800px',height:'500px'
        
      });
      dialogRef.afterClosed().subscribe(() => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        window.location.reload();
      });
  }
}


changeStatus(payment: any){

  this.loader.open();
  this.web.get(this.web.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + payment.id).subscribe(tdc =>{

    let json = {token : tdc.result.tokenCreditCard }

    this.web.post(json,this.web.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard/' + tdc.result.id + '/delete').subscribe(response =>{
        this.loader.close();
        this.notification.showSuccess("Actualizada correctamente");
        window.location.reload();
      }, (err) => {
        this.loader.close();
        this.notification.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
      })

    }, err =>{
      this.loader.close();
      //console.log(err);
     this.notification.showError(err);
    }) ;
}

}