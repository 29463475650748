import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';
import { ValidatePopupComponent } from './validate-popup/validate-popup.component';
import { MisdireccionesComponent } from 'src/app/checkout/misdirecciones/misdirecciones.component';
import { MistarjetasComponent } from 'src/app/checkout/mistarjetas/mistarjetas.component';

@Component({
  selector: 'app-update-suscription',
  templateUrl: './update-suscription.component.html',
  styleUrls: ['./update-suscription.component.scss']
})
export class UpdateSuscriptionComponent implements OnInit {

  form: any;
  adress: any;
  tdc: any;
  tarjetas: any = [];
  canPause: boolean = true;
  canCancel: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<UpdateSuscriptionComponent>,
    private dialog: MatDialog, private web: WebService,
    @Inject(MAT_DIALOG_DATA) public data: any, private notification: NotificationService, private loader: LoaderService, private authService: AuthService
  ) {
    this.form = new FormGroup({
      id: new FormControl(this.data.suscription.id, Validators.required),
      product: new FormControl(this.data.suscription.plan, Validators.required),
      price: new FormControl('$' + this.data.suscription.plan.feeCost_plan, Validators.required),
      frecuency: new FormControl(this.data.suscription.plan.frecuency.name_frecuency),
      next: new FormControl(this.data.suscription.next_bill_date, Validators.required),
      adress: new FormControl('', Validators.required),
      name_tdc: new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      province: new FormControl('', Validators.required),
      corregimiento: new FormControl('', Validators.required)
    });

    // Log the product value at the initialization
    //console.log('Product at initialization:', this.form.controls['product'].value);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.loader.open();

    // Cargamos la informacion de la direccion asociada a la suscripcion
    this.web.get(this.web.HOST + "/adress/" + this.data.suscription.adress_id).subscribe(response => {
      //console.log('Adress data:', response);
      this.form.controls["adress"].setValue(response.result.adress);
      if (response.result.province != null) {
        this.form.controls["province"].setValue(response.result.province.name);
        this.form.controls["corregimiento"].setValue(response.result.corregimiento.name);
      }

      // Buscamos la tarjeta del usuario
      if (this.data.suscription.creditCardToken != null) {
        this.web.get(this.web.HOST + '/customer/' + this.data.suscription.customer.id + '/creditcard').subscribe(response => {
          //console.log('Credit card data:', response);
          this.tarjetas = response.result;

          let t = this.tarjetas[this.tarjetas.findIndex((t: { tokenCreditCard: any; }) => t.tokenCreditCard == this.data.suscription.creditCardToken.token_creditCardToken)];
          this.form.controls["number_tdc"].setValue(t.creditCard.maskCreditCard);
          this.form.controls["name_tdc"].setValue(t.creditCard.nameHolderCreditCard);

          // Para el mes
          let month;
          switch (t.creditCard.yearMonthExpiration.substr(t.creditCard.yearMonthExpiration.length - 2)) {
            case "01": month = "ENERO"; break;
            case "02": month = "FEBRERO"; break;
            case "03": month = "MARZO"; break;
            case "04": month = "ABRIL"; break;
            case "05": month = "MAYO"; break;
            case "06": month = "JUNIO"; break;
            case "07": month = "JULIO"; break;
            case "08": month = "AGOSTO"; break;
            case "09": month = "SEPTIEMBRE"; break;
            case "10": month = "OCTUBRE"; break;
            case "11": month = "NOVIEMBRE"; break;
            default: month = "DICIEMBRE";
          }

          this.form.controls["year"].setValue("20" + t.creditCard.expYearCreditCard);
          this.form.controls["month"].setValue(month);
          this.loader.close();
        }, err => {
          //console.log("Error al cargar tarjeta:", err);
          this.loader.close();
        });
      } else {
        this.loader.close();
      }
    }, err => {
      this.notification.showError(err);
      this.loader.close();
    });

    const product = this.form.controls['product'].value;
    const today = new Date();
    const endDate = new Date(this.data.suscription.endDate_customerPlan);

    if (product.qty_installments_to_collect_plan > 0 && today < endDate) {
      this.canPause = false;
      this.canCancel = false;
    }
  }

  update() {
    this.loader.open();

    if (this.adress != null || this.tdc != null) {
      const data: any = {}; //this.formEdit.value;
      data.customer = this.authService.getCustomer();
      if (this.adress != null) {
        data.adress = { id: this.adress };
      }

      if (this.tdc != null) {
        data.tdc = { id: this.tdc };
      }

      data.hasPlan = this.form.value.id;
      //console.log('Update data:', data);
      //console.log('Product at update:', this.form.controls['product'].value);

      this.web.put(data, this.web.HOST + "/ecommerce/hasplan/" + data.hasPlan).subscribe(response => {
        this.notification.showSuccess("Actualizada correctamente");
        window.location.reload();
        this.close(4);
        this.loader.close();
      }, err => {
        //console.log('Error actualizando suscripción:', err);
        this.loader.close();
        this.notification.showError(err);
      });
    } else {
      this.notification.showInfo("Debe seleccionar una direccion de envío o una tarjeta distinta a la actual");
      this.loader.close();
    }
  }

  close(param: any) {
    this.dialogRef.close(param);
    window.location.reload();
  }

  addressesPopUp(data: any = {}) {
    let dialogRef: MatDialogRef<any> = this.dialog.open(MisdireccionesComponent, {
      width: '100%',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      //console.log('Address selected:', res);
      // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
      this.adress = res.id;
      this.form.controls["adress"].setValue(res.adress);
      this.form.controls["province"].setValue(res.province.name);
      this.form.controls["corregimiento"].setValue(res.corregimiento.name);
    });
  }

  tdcPopUp(data: any = {}) {
    let dialogRef: MatDialogRef<any> = this.dialog.open(MistarjetasComponent, {
      width: '100%',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      // Log the selected credit card data
      //console.log('Credit card selected:', res);

      const selectedMonth = parseInt(res.yearMonthExpiration.substr(2, 2), 10);
      const selectedYear = parseInt("20" + res.yearMonthExpiration.substr(0, 2), 10);

      //console.log('Selected month:', selectedMonth);
      //console.log('Selected year:', selectedYear);

      const endDate = new Date(this.data.suscription.endDate_customerPlan);
      const endMonth = endDate.getMonth() + 1; // getMonth() returns month from 0-11
      const endYear = endDate.getFullYear();

      //console.log('End month:', endMonth);
      //console.log('End year:', endYear);

      // Check if the selected card expiration date is before the endDate_customerPlan
      if (selectedYear < endYear || (selectedYear === endYear && selectedMonth < endMonth)) {
        this.notification.showError("La tarjeta seleccionada expira antes de la fecha de finalización de la suscripción.");
        return;
      }

      this.tdc = res.token;
      this.form.controls["name_tdc"].setValue(res.nameHolderCreditCard);
      this.form.controls["number_tdc"].setValue(res.maskCreditCard);

      let month;
      switch (res.yearMonthExpiration.substr(2, 2)) {
        case "01": month = "ENERO"; break;
        case "02": month = "FEBRERO"; break;
        case "03": month = "MARZO"; break;
        case "04": month = "ABRIL"; break;
        case "05": month = "MAYO"; break;
        case "06": month = "JUNIO"; break;
        case "07": month = "JULIO"; break;
        case "08": month = "AGOSTO"; break;
        case "09": month = "SEPTIEMBRE"; break;
        case "10": month = "OCTUBRE"; break;
        case "11": month = "NOVIEMBRE"; break;
        case "12": month = "DICIEMBRE";
      }

      this.form.controls["year"].setValue("20" + res.yearMonthExpiration.substr(0, 2));
      this.form.controls["month"].setValue(month);
    });
  }

  validateSubscription(action: string, value: number) {
    if (!this.canPauseOrCancel(action)) return;
    this.validatePopup(action, value);
  }

  validatePopup(action: string, value: number) {
    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidatePopupComponent, {
      width: '50%',
      disableClose: true,
      data: { action: action, type: value }
    });
    dialogRef.afterClosed().subscribe(res => {
      //console.log('Validation result:', res);
      if (!res) {
        return;
      }
      // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
      switch (value) {
        case 1: this.activateSuscription(); break;
        case 2: this.pauseSuscription(); break;
        default: this.cancelSuscription();
      }
    });
  }

  pauseSuscription() {
    this.loader.open();
    this.web.put({ action: 3 }, this.web.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/status").subscribe(response => {
      this.loader.close();
      this.close(1);
    }, err => {
      this.loader.close();
      this.notification.showError(err);
    });
  }

  activateSuscription() {
    this.loader.open();
    this.web.put({ action: 1 }, this.web.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/status").subscribe(response => {
      this.loader.close();
      this.close(0);
    }, err => {
      this.loader.close();
      this.notification.showError(err);
    });
  }

  cancelSuscription() {
    this.loader.open();
    this.web.put({ action: 2 }, this.web.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/status").subscribe(response => {
      this.loader.close();
      this.close(2);
    }, err => {
      this.loader.close();
      this.notification.showError(err);
    });
  }

  private canPauseOrCancel(action: string): boolean {
    const product = this.form.controls['product'].value;
    const today = new Date();
    const endDate = new Date(this.data.suscription.endDate_customerPlan);

    if (product.qty_installments_to_collect_plan > 0 && today < endDate) {
      return false;
    }
    return true;
  }
}
